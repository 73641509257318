body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h5 {
    font-family: "Roboto-Bold", sans-serif;
}

@font-face {
    font-family: "Roboto";
    src: url("fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-Bold";
    src: url("fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-Light";
    src: url("fonts/Roboto-Light.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

.light-text {
    font-family: "Roboto-Light", sans-serif;
}

code {
    font-family: Roboto, 'Courier New',
    monospace;
}

.main-bg-color {
    background-color: #014157;
}

.animate__animated.animate__flash {
    --animate-duration: 1s;
    --animate-delay: 2s;
}